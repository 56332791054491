<template>
  <div class="bsc-gift-list">
    <FreeFiftItem
      v-for="(item, index) in giftItem?.goodList"
      :key="index"
      :giftItem="item"
      :isInDrawerList="true"
    />
  </div>
</template>
<script setup lang="ts">
  import { template as _commonTemplate } from '@shein/common-function'
  import FreeFiftItem from './free-gift-item.vue'
  import { C_BenefitFloor } from '../../../../types'
  type propsType = {
    giftItem?: C_BenefitFloor.RewardItem,
  }

  withDefaults(defineProps<propsType>(), {})

</script>
<style lang="less">
.bsc-gift-list {
  display: flex;
  gap: 6/37.5rem;
}
.bsc-gift-list .bsc-gift-item {
  flex: none;
}
</style>
