<template>
  <div class="bsc-bfp-floor">
    <Card
      class="bsc-bfp-floor__icon"
      :reward-item="rewardItem"
    />
    <div class="bsc-bfp-floor__title">
      {{ rewardItem.desc }}
    </div>
  </div>
</template>
<script setup lang="ts">
import Card from './Card.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

withDefaults(
  defineProps<{
    rewardItem: C_BenefitFloor.RewardItem
  }>(),
  {
    rewardItem: () => ({
      type: E_BenefitFloor.BenefitType.ReturnCredit,
      desc: '',
      returnCredit: {} as C_BenefitFloor.ReturnCredit,
    }),
  },
)

</script>
<style lang="less" scoped>
.bsc-bfp-floor {
  width: 3.7333rem;
  height: 1.4933rem;
  padding: .2667rem .2133rem;
  border-radius: .2133rem;
  background: linear-gradient(97deg, rgba(255, 197, 130, 0.20) 9.36%, rgba(255, 255, 255, 0.20) 91.53%), #FFF;
  box-shadow: 0 .0533rem .1067rem 0 rgba(140, 8, 0, 0.08);
  border-radius: .16rem;
  display: flex;
  align-items: center;
  line-height: 1.2;

  &__title {
    font-size: .3733rem;
    font-weight: 1000;
    color: #FA6338;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-left: .2133rem;
  }
}
</style>
