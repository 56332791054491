<template>
  <div class="benefit-bar-main">
    <AdapterRichText
      default-size="14"
      min-size="12"
      :text="title"
    />
    <div
      v-if="endTime"
      class="benefit-bar-main__timer"
    >
      <span
        v-if="prefixTitle"
        v-html="prefixTitle"
      ></span>
      <BackgroundTimer
        :end-time-stamp="endTime"
      />
      <span
        v-if="suffixTitle"
        v-html="suffixTitle"
      ></span>
    </div>
  </div>
</template>

<script name="BenefitBarMain" setup lang="ts">
import { computed } from 'vue'
import AdapterRichText from '../AdapterRichText.vue'
import BackgroundTimer from '../CountDown/BackgroundTimer.vue'
import type { C_BenefitFloor } from '../../../../types'

const props = defineProps<{
  language: C_BenefitFloor.LanguageType,
  title: string,
  endTime?: string,
}>()

const placeholderIndex = computed(() => props.language.CountDownText.indexOf('{0}'))

const prefixTitle = computed(() => {
  if (placeholderIndex.value < 0) return props.language.CountDownText
  return props.language.CountDownText.slice(0, placeholderIndex.value).replaceAll(' ', '&nbsp;')
})

const suffixTitle = computed(() => {
  if (placeholderIndex.value < 0) return ''
  return props.language.CountDownText.slice(placeholderIndex.value + 3, props.language.CountDownText.length).replaceAll(' ', '&nbsp;')
})

</script>

<style lang="less" scoped>
.benefit-bar-main {
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  font-weight: 700;

  &__timer {
    display: flex;
    gap: 8/75rem;
    color: #666;
    font-size: 12px;
    font-weight: 590;
  }
}
</style>
