<template>
  <div class="credit-floor">
    <CreditIcon
      class="credit-floor__icon"
      :reward-item="rewardItem"
    />
    <AdapterText
      class="credit-floor__title"
      :min-size="12"
      :lines="2"
      :text="rewardItem.desc"
    />
  </div>
</template>
<script name="CreditFloor" setup lang="ts">
// @ts-ignore 该库未提供类型定义
import { SAdapterText as AdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import CreditIcon from './CreditIcon.vue'
import { E_BenefitFloor, type C_BenefitFloor } from '../../../../types'

withDefaults(
  defineProps<{
    rewardItem: C_BenefitFloor.RewardItem
  }>(),
  {
    rewardItem: () => ({
      type: E_BenefitFloor.BenefitType.ReturnCredit,
      desc: '',
      returnCredit: {} as C_BenefitFloor.ReturnCredit,
    }),
  },
)

</script>
<style lang="less" scoped>
.credit-floor {
  width: 280/75rem;
  height: 112/75rem;
  padding: 20/75rem 16/75rem;
  border-radius: 16/75rem;
  border: 1px solid rgba(255, 255, 255, 0.90);
  background: linear-gradient(97deg, rgba(160, 211, 151, 0.20) 9.36%, rgba(255, 255, 255, 0.20) 91.53%), #FFF;
  box-shadow: 0 4/75rem 8/75rem 0 rgba(140, 8, 0, 0.08);
  border-radius: 12/75rem;
  display: flex;
  align-items: center;
  line-height: 1.2;
  gap: 16/75rem;

  &__title {
    flex: 1;
    height: 100%;
    font-size: 14px;
    font-weight: 860;
    color: #2E764B;
  }
}
</style>
