<template>
  <div class="bsc-bf-point-list">
    <div
      v-for="(item, index) in getPointList"
      :key="`${index}_${expandList.includes(index)}`"
      class="bsc-bf-point-list__wrap"
      :style="{
        paddingTop: index === 0 ? 0 : '.2667rem',
        paddingBottom: index === getPointList?.length - 1 ? 0 : '.2667rem',
        borderBottomStyle: 'solid',
        borderBottomColor: '#E4E4E4',
        borderBottomWidth: index !== getPointList?.length - 1 ? '.0133rem' : 0,
      }"
    >
      <div class="bsc-bf-point-list__bar">
        <div
          class="bar__left">
          <div class="title__wrap">
            <span
              :class="{
                'title__regular-point': item.type === 'regularPoint',
                'title__extra-point': item.type === 'extraPoint',
                'title__card-point': item.type === 'cardPoint',
              }
              ">
              {{ item.title }}
            </span>
            <template v-if="!expandList.includes(index) && item.tailIconList?.length > 0">
              <span
                v-for="(tail, tailIndex) in item.tailIconList"
                :key="tailIndex"
                class="title__tail"
              >
                <img
                  class="title__tail-icon"
                  :src="tail.icon" />
                {{ tail.text }}
              </span>
            </template>
            <BackgroundTimer
              v-if="item.endTime"
              class="title__countdown"
              :endTimeStamp="+item.endTime"
            />
          </div>
          <span v-html="item.desc"></span>
        </div>
        <div class="bar__right">
          {{ item.pointTip }}
          <component
            :is="sui_icon_more_down_28px"
            v-if="item.subPointList?.length > 0"
            :style="{
              transform: `rotate(${expandList.includes(index) ? 180 : 0}deg)`,
            }"
            size="16px"
            color="#959595"
            @click="() => handleExpand(index)"
          />
        </div>
      </div>
      <div
        v-if="item.subPointList?.length > 0 && expandList.includes(index)"
        class="bsc-bf-point-list__info"
      >
        <div
          v-for="(subItem) in item.subPointList"
          :key="subItem.title"
          class="info__wrap"
        >
          <span class="info__title">
            <img
              v-if="subItem.icon"
              class="info__icon"
              :src="subItem.icon"
            />
            {{ subItem.title }}
          </span>
          <span>{{ subItem.pointTip }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
// @ts-ignore 该库未提供类型定义
import { sui_icon_more_down_28px } from '@shein-aidc/icon-vue3'
import BackgroundTimer from '../CountDown/BackgroundTimer.vue'
import { type C_BenefitFloor } from '../../../../types'

type IProps = {
  pointItem: C_BenefitFloor.PopUpPoint
}

const expandList = ref<number[]>([])

const props = withDefaults(defineProps<IProps>(), {
  pointItem: () => ({
    pointList: [],
  }),
})

const getPointList = computed(() => props.pointItem?.pointList || [])

const handleExpand = (index) => {
  const i = expandList.value.findIndex(item => item === index)
  i === -1 ? expandList.value.push(index) : expandList.value.splice(i, 1)
}

</script>

<style lang="less">
.bsc-bf-point-list {
  font-family: system-ui;
  padding-right: .32rem;
  &__wrap {
    justify-content: space-between;
  }
  &__bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .bar__left {
      display: flex;
      flex-direction: column;
      .title {
        &__wrap {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: .1067rem;
        }
        &__regular-point {
          color: #000;
          font-size: .3733rem;
          font-weight: 400;
          line-height: 1.1;
        }
        &__extra-point {
          font-size: .3733rem;
          font-weight: 860;
          background-image: linear-gradient(to right, #FF553B 0%, #FF8A01 100%);
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          line-height: 1.1;
        }
        &__card-point {
          color: #000;
          font-size: .3733rem;
          font-weight: 400;
          line-height: 1.1;
        }
        &__countdown {
          margin-left: .2133rem;
        }
        &__tail {
          display: flex;
          align-items: center;
          color: #666;
          text-align: right;
          font-size: .32rem;
          font-weight: 400;
          line-height: normal;
          border: .0133rem solid #E5E5E5;
          border-radius: 2.6667rem;
          padding: .08rem .1067rem;
          &-icon {
            height: .3733rem;
            margin-right: .0533rem;
          }
        }
      }
    }
    .bar__right {
      color: #FF6B2D;
      text-align: center;
      font-family: "SF Pro";
      font-size: .3733rem;
      font-style: normal;
      font-weight: 700;
      line-height: 1.1;
    }
  }
  &__info {
    margin-top: .2667rem;
    border-radius: .1067rem;
    padding: .2133rem;
    color:  #767676;
    font-size: .32rem;
    font-weight: 400;
    background-color: #F6F6F6;
    .info__wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: .32rem;
    }
    .info__title {
      display: flex;
      align-items: center;
    }
    .info__icon {
      height: .3733rem;
      margin-right: .2133rem;
    }
    :last-child {
      margin-bottom: 0;
    }
  }
}
</style>
