<template>
  <div
    class="bsc-benefit-coupon__item bsc-benefit-coupon__flex-none"
    :class="{'bsc-benefit-coupon__free-item': couponItem?.type === 'freeShipping', 'bsc-benefit-coupon__item-mini': isMini}"
  >
    <template v-if="couponItem?.type === 'discount'">
      <div
        class="bsc-benefit-coupon__percent-container"
      >
        <AdapterText
          class="bsc-benefit-coupon__percent-container_amount"
          :class="{'bsc-benefit-coupon__percent-container_amount-mini': isMini}"
          :text="couponItem?.displayText?.[0]"
        />
        <span class="bsc-benefit-coupon__percent-container_identify">{{ couponItem?.displayText?.[1] }}</span>
      </div>
      <div
        v-if="!isMini"
        class="bsc-benefit-coupon__item_content"
      >{{ couponItem?.displayText?.[2] }}</div>
    </template>
    <template v-else-if="couponItem?.type === 'amount'">
      <AdapterText
        class="bsc-benefit-coupon__percent-container_amount"
        :class="{'bsc-benefit-coupon__percent-container_amount-mini': isMini}"
        :text="`${couponItem?.displayText?.[0]}${isShowPlus ? '+' : ''}`"
        :min-size="12"
      />
      <div
        v-if="!isMini"
        class="bsc-benefit-coupon__item_content"
      >{{ couponItem?.displayText?.[1] }}</div>
    </template>
    <template v-else-if="couponItem?.type === 'freeShipping'">
      <AdapterText
        class="bsc-benefit-coupon__free-item_text"
        :class="{'bsc-benefit-coupon__free-item_text-mini': isMini}"
        :text="couponItem?.displayText?.[0]"
        :lines="2"
      />
    </template>
    <div
      v-if="isShowItemNum && couponItem?.num"
      class="bsc-benefit-coupon__item_num"
      :class="{'bsc-benefit-coupon__item_num-free':couponItem?.type === 'freeShipping'}"
    >
      x{{ couponItem?.num }}
    </div>
  </div>
</template>
<script setup lang="ts">
  import { template as _commonTemplate } from '@shein/common-function'
  // @ts-ignore 该库未提供类型定义
  import { SAdapterText as AdapterText } from '@shein-aidc/sui-adapter-text/mobile'
  import { C_BenefitFloor } from '../../../../types'
  type propsType = {
    isShowItemNum?: boolean,
    couponItem?: C_BenefitFloor.ReturnCouponItem,
    isMini?: boolean,
    isShowPlus?: boolean,
  }
  withDefaults(defineProps<propsType>(), {
    isMini: false,
    index: 0,
    isShowPlus: false,
  })
</script>
<style lang="less">
.bsc-benefit-coupon__item.bsc-benefit-coupon__flex-none,
.bsc-benefit-coupon__item .bsc-benefit-coupon__percent-container_amount,
.bsc-benefit-coupon__item .bsc-benefit-coupon__item_content,
.bsc-benefit-coupon__item .bsc-benefit-coupon__percent-container
{
  flex: none;
}

.bsc-benefit-coupon {
  &__item {
    position: relative;
    width: 128/75rem;
    height: 36/37.5rem;
    padding: 0 16/75rem;
    // box-shadow: 0 0 4/375rem 0 rgba(255, 92, 56, 0.48);
    background: url("https://img.ltwebstatic.com/images3_ccc/2024/11/15/1f/1731664606a4bcd0c19f0c2327176a469175439b7e.png");
    background-size: 100% 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    &_num {
      padding: 1/37.5rem 3/37.5rem;
      position: absolute;
      top: 0;
      right: 0;
      border-radius: 0px 6px 0px 4px;
      background: #FF8143;
      color: #FFFFFF;
      font-family: "SF Pro";
      font-size: 6px;
      font-style: normal;
      font-weight: 700;
      line-height: 1;
    }
    &_num-free{
      background: #169E00;
    }
    &_content {
      text-align: center;
      font-family: "SF Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 590;
      line-height: 0.6;
    }
  }
  &__item-mini{
    width: 44/37.5rem;
    height: 24/37.5rem;
    padding: 5/37.5rem 6/37.5rem;
  }
  &__free-item {
    background: url("https://img.ltwebstatic.com/images3_ccc/2024/11/22/eb/1732255915ff7dc41a5ef0392e49516037368e8e05.png");
    background-size: 100% 100%;
    fill: linear-gradient(208deg, rgba(255, 255, 255, 0.60) 15.77%, rgba(255, 255, 251, 0.00) 48.19%), linear-gradient(106deg, #FFF 6.9%, #F1FFEF 38.67%, #D5FBCF 78.96%, #FFF 102.57%);
    stroke-width: 1px;
    stroke: #7CCD6F;
    &_text {
      display: flex !important;
      align-items: center;
      justify-content: center;
      color: #169E00;
      text-align: center;
      font-family: "SF Pro";
      font-size: 10px;
      font-style: normal;
      font-weight: 1000;
      line-height: 1;
    }
    &_text-mini {
      font-size: 8px;
    }
  }
  &__percent-container{
    display: flex;
    align-items: center;
    justify-content: center;
    &_amount {
      font-family: "SF Pro";
      font-size: 18px;
      text-align: center;
      font-style: normal;
      font-weight: 1000;
      line-height: 1;
      // background: linear-gradient(to right, #6d5b46, #351e08);
      // -webkit-background-clip: text;
      // color: transparent;
    }
    &_amount-mini {
      // font-size: 12px;
      flex: auto !important;
    }
    &_identify {
      font-size: 12px;
    }
  }
}

</style>
