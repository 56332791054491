<template>
  <div
    class="bsc-gift-item"
    :class="{
      'bsc-gift-item-prime': giftItem?.type === 'prime',
      'bsc-gift-item-mini': isMini,
      'bsc-item-on-drawer': isInDrawerList
    }"
  >
    <div
      class="bsc-gift-item__content"
      :class="{
        'bsc-gift-item-mini__content': isMini,
        'bsc-gift-item__content_on-drawer': isInDrawerList
      }"
    >
      <img
        class="bsc-gift-item__content_img"
        :src="giftItem?.imgUrl"
      />
    </div>
    <div
      class="bsc-gift-item__belt"
      :class="{
        'bsc-gift-item__belt-prime': giftItem?.type === 'prime',
        'bsc-gift-item-mini__belt': isMini,
        'bsc-gift-item__belt_on-drawer': isInDrawerList
      }"
    >{{ giftItem?.beltTip }}</div>
  </div>
</template>
<script setup lang="ts">
  import { template as _commonTemplate } from '@shein/common-function'
  import { C_BenefitFloor } from '../../../../types'
  type propsType = {
    giftItem?: C_BenefitFloor.FreeGiftItem,
    isMini?: boolean,
    isInDrawerList?: boolean,
  }
  withDefaults(defineProps<propsType>(), {
    isMini: false,
    isInDrawerList: false,
  })


</script>
<style lang="less">
  .bsc-gift-item-prime {
    border: 0.821/37.5rem solid #873C00;
  }
  .bsc-gift-item {
    display: flex;
    position: relative;
    flex-direction: column;
    box-sizing: border-box;
    width: 36/37.5rem;
    height: 36/37.5rem;
    border-radius: 3.284/37.5rem;
    border: 0.821/37.5rem solid #ED785E;
    overflow: hidden;
    &__content {
      height: 36/37.5rem - 9.957/37.5rem - 0.821/37.5rem - 0.821/37.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      &_img {
        max-width: 100%;
      }
    }
    &__content_on-drawer {
      height: (46-1.436-1.436-13.788)/37.5rem;
    }
    &__belt {
      display: flex;
      background: linear-gradient(to bottom, #EE5230, #F97743);
      height: 9.957/37.5rem;
      color: #FFF;
      font-family: "SF Pro";
      font-size: 9px;
      font-style: normal;
      font-weight: 860;
      text-transform: uppercase;
      align-items: center;
      justify-content: center;
      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
    &__belt-prime {
      background: linear-gradient(to bottom, #873c00, #873c00);
    }
    &__belt_on-drawer {
      height: 13.788/37.5rem;
    }
  }
  .bsc-gift-item-mini {
    width: 23.333/37.5rem;
    height: 23.333/37.5rem;
    &__content {
      height: 23.333/37.5rem - 7.447/37.5rem - 0.821/37.5rem - 0.821/37.5rem;
    }
    &__belt {
      height: 7.447/37.5rem;
      font-size: 6px;
    }
  }
  .bsc-item-on-drawer {
    width: 46/37.5rem;
    height: 46/37.5rem;
    border-width: 1.436/37.5rem;
  }
</style>
