<template>
  <div class="benefit-floor-main">
    <div class="benefit-floor-main__left">
      <AdapterRichText
        default-size="14"
        min-size="12"
        :text="title"
      />
      <BackgroundTimer
        v-if="endTime"
        class="benefit-floor-main__timer"
        :end-time-stamp="endTime"
      />
    </div>
    <div class="benefit-floor-main__right">
      <div
        v-if="totalValueTip"
        class="benefit-floor-main__value"
      >
        {{ totalValueTip }}
      </div>
      <sui_icon_more_right_12px
        :is-rotate="GB_cssRight"
        size="12px"
        color="#767676"
      />
    </div>
  </div>
</template>

<script name="BenefitFloorMain" setup lang="ts">
import AdapterRichText from '../AdapterRichText.vue'
// @ts-ignore 该库未提供类型定义
import { sui_icon_more_right_12px } from '@shein-aidc/icon-vue3'
import BackgroundTimer from '../CountDown/BackgroundTimer.vue'

const { GB_cssRight = '' } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

defineProps<{
  title: string,
  endTime?: string,
  totalValueTip?: string
}>()

</script>

<style lang="less" scoped>
.benefit-floor-main {
  white-space: nowrap;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24/75rem;
  line-height: 1.2;

  &__left {
    display: flex;
    align-items: center;
    flex: 1;
    overflow: hidden;
    font-weight: 700;
  }

  &__right {
    display: flex;
    align-items: center;
    overflow: hidden;
  }

  &__title {
    flex: 1;
  }

  &__timer {
    font-weight: 590;
    margin: 0 16/75rem 0 8/75rem;
  }

  &__value {
    color: #000;
    font-family: "SF Pro";
    font-size: 12px;
  }
}
</style>
