<template>
  <div class="benefit-drawer-header">
    <img
      class="benefit-drawer-header__background"
      src="https://img.ltwebstatic.com/images3_ccc/2024/12/06/95/17334673724251677133e3b1b40fb05ef716d84a83.webp"
    />
    <div
      class="benefit-drawer-header__title"
      v-html="title"
    >
    </div>
    <div
      v-if="totalValueTip || endTime"
      class="benefit-drawer-header__subtitle"
    >
      <div v-if="totalValueTip">
        <span>{{ language.ValueText }}&nbsp;</span>
        <span class="benefit-drawer-header__subtitle-value">{{ totalValueTip }}</span>
      </div>
      <BackgroundTimer
        v-if="endTime"
        :end-time-stamp="endTime"
        :style="{
          '--timer-item-width': '0.48rem',
          '--timer-item-height': '0.427rem',
          '--timer-item-font-size': '11px',
        }"
      />
    </div>
    <sui_icon_close_16px
      class="benefit-drawer-header__close"
      size="20px"
      color="#888"
      @click.stop="emits('close')"
    />
  </div>
</template>
<script name="BenefitDrawerHeader" setup lang="ts">
// @ts-ignore 该库未提供类型定义
import { sui_icon_close_16px } from '@shein-aidc/icon-vue3'

import BackgroundTimer from '../CountDown/BackgroundTimer.vue'
import type { C_BenefitFloor } from '../../../../types'

defineProps<{
  language: C_BenefitFloor.LanguageType,
  title: string,
  totalValueTip?: string,
  endTime?: string
}>()

const emits = defineEmits(['close'])

</script>
<style lang="less" scoped>
.benefit-drawer-header {
  font-size: 18px;
  font-weight: 700;
  color: #222;
  padding: 24/75rem 0 26/75rem 0;
  position: relative;
  display: flex;
  flex-direction: column;

  &__background {
    width: 100%;
    height: 100%;
    background-size: cover;
    position: absolute;
    left: 0;
    top: 0;
  }

  &__close {
    position: absolute;
    right: 24/75rem;
    top: 24/75rem;;
  }

  &__title {
    position: relative;
    line-height: 1.2;
    max-width: calc(100% - 1.387rem);
    align-self: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__subtitle {
    color: #222;
    font-weight: 700;
    font-size: 14px;
    margin-top: 42/75rem;
    padding: 0 20/75rem;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    line-height: 1.2;
  }

  &__subtitle-value {
    color: #FF344E;
    font-size: 22px;
    font-weight: 860;
  }
}
</style>
