import { createApp, ref, App, computed, onBeforeUnmount, h, Ref } from 'vue'
import type { C_BenefitFloor } from '../../../types'

const benefitDrawerComp = ref<App | null>(null)
const benefitDrawerVisible = ref(false)
const benefitDrawerRef: Ref = ref(null)
const benefitDrawerContainerRef: Ref = ref(null)
const footerSlotRef: Ref = ref(null)

export const useBenefitDrawer = () => {

  const importComponent = async () => {
    if (benefitDrawerComp.value) {
      return benefitDrawerComp.value
    } else {
      const app = await new Promise(resolve => {
        import(
          /* webpackChunkName: "benefit-drawer" */ '../components/BenefitDrawer/Index.vue'
        ).then(components => {
          const benefitDrawer = components.default
          const app = createApp({
            render() {
              return h(benefitDrawer, {
                ref: benefitDrawerRef,
                visible: benefitDrawerVisible.value,
                onClose: visible => benefitDrawerVisible.value = !!visible,
              }, { footer: () => footerSlotRef.value })
            },
          })
          if (typeof window !== 'undefined') {
            if (!benefitDrawerContainerRef.value) {
              benefitDrawerContainerRef.value = document.createElement('div')
            }
            document.body.appendChild(benefitDrawerContainerRef.value)
          }
          app.mount(benefitDrawerContainerRef.value)
          resolve(app)
        })
      })
      benefitDrawerComp.value = app as any
      return app
    }
  }

  const openDrawer = async ({ data, footer, drawerBottom, language }: {
    data: C_BenefitFloor.PopUpData,
    footer: any,
    drawerBottom?: string,
    language: C_BenefitFloor.LanguageType
  }) => {
    const instance = await importComponent() as any
    if (!instance) return
    benefitDrawerVisible.value = true
    footerSlotRef.value = footer()
    benefitDrawerRef.value.openDrawer({ data, bottom: drawerBottom, language })
  }

  const closeDrawer = () => {
    benefitDrawerVisible.value = false
  }

  const destroyDrawer = () => {
    closeDrawer()
    benefitDrawerComp.value?.unmount?.()
    benefitDrawerContainerRef.value?.parentNode?.removeChild?.(benefitDrawerContainerRef.value)
    benefitDrawerComp.value = null
  }

  onBeforeUnmount(() => {
    destroyDrawer()
  })

  return {
    openDrawer,
    closeDrawer,
    drawerVisible: computed(() => benefitDrawerVisible.value),
  }
}

export default useBenefitDrawer
