<template>
  <CountDown
    v-slot="{timer}"
    class="benefit-background-timer"
    :end-time-stamp="endTimeStamp"
  >
    <span>{{ timer.timeObj.H }}</span>
    <span>:</span>
    <span>{{ timer.timeObj.M }}</span>
    <span>:</span>
    <span>{{ timer.timeObj.S }}</span>
  </CountDown>
</template>

<script name="BackgroundTimer" setup lang="ts">
import CountDown from './Index.vue'

defineProps({
  endTimeStamp: {
    type: [Number, String],
    default: 0,
  },
})
</script>

<style lang="less" scoped>
.benefit-background-timer {
  display: flex;
  align-items: center;
  line-height: 1.5;

  > span {
    display: inline-block;
    height: var(--timer-item-height, 27/75rem);
    text-align: center;
    font-size: var(--timer-item-font-size, 9px);
    font-family: "SF Pro";
    font-weight: 590;

    &:nth-child(odd) {
      width: var(--timer-item-width, 28/75rem);
      background-color: #000;
      color: #fff;
      border-radius: 2/75rem;
    }

    &:nth-child(even) {
      color: #000;
      margin: 0 8/75rem;
    }
  }
}
</style>
